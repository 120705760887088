import React from "react";
import {graphql, Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import styled, { css } from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"
import LangSelector from "../components/LangSelector";
import img01Src from '../images/foto-06.jpg';
import img02Src from '../images/foto-07.jpg';
import img03Src from '../images/foto-08.jpg';
import { APARTMENT_SELECTION } from "../routes";
import { TopPanel, Title } from '../components/common/topPanel';
import Gallery from "../components/Gallery";
import Map from "../components/Map";
import ContentWrapper from "../components/common/contentWrapper";
import bgShadowSrc from '../images/bg-shadow.jpg';
import profitSvg from '../images/svg/icon-profit.svg';
import increaseSvg from '../images/svg/icon-value-increase.svg';
import priceSvg from '../images/svg/icon-price.svg';

const iconStyle = css`
  height: 60px;
  width: auto;
  /* fill: ${({theme}) => theme.colors.primary}; */
`;

const ProfitIcon = styled(profitSvg)`
  ${iconStyle};
`;

const IncreaseIcon = styled(increaseSvg)`
  ${iconStyle};
`;

const PriceIcon = styled(priceSvg)`
  ${iconStyle};
`;

const Content = styled.div`
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const LeftSubtitle = styled(Subtitle)`
  text-align: left;

  &:before {
    left: 0;
    transform: none;
  }
`;

const CenteredText = styled.div`
  text-align: left;
  color: #58595b;

  p {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media(min-width: 768px) {
    text-align: center;
  }
`;

const LeftText = styled(CenteredText)`
  text-align: left;
`;

const Section = styled.div`
  background: rgb(0,0,0);
  /* background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.05926120448179273) 0%, rgba(255,255,255,1) 12%); */
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
  padding: 70px 1.5rem;

  ${Subtitle} {
    text-align: left;

    &:before {
      left: 0;
      transform: none;
    }
  }

  @media(min-width: 768px) {
    padding: 70px;

    ${Subtitle} {
      text-align: center;

      &:before {
        content: '';
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }

    ${LeftSubtitle} {
      text-align: left;

      &:before {
        left: 0;
        transform: none;
      }
    }

  }
`;

const SpecialSection = styled(Section)`
  padding: 70px 1.5rem 50px;

  @media(min-width: 768px) {
    padding: 110px 70px 50px;
  }
`;

const DarkSection = styled.div`
  background-color: #313131;
  padding: 70px 1.5rem;

  ${Subtitle} {
    color: #ffffff;
    margin-top: 1.5rem;
  }

  ${CenteredText} {
    color: #ffffff;
  }

  @media(min-width: 768px) {
    padding: 70px;

    /* ${Subtitle} {
      margin-top: 0;
    } */
  }

  @media(min-width: 1400px) {

    ${Subtitle} {
      margin-top: 0;
    }
  }

`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 6.25rem;
  row-gap: 2rem;

  @media(min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ImgGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -105px;

  img {
    width: 100%;
    height: auto;
  }

  img:last-child {
    display: none;
  }

  @media(min-width: 1400px) {
    margin-bottom: -105px;

    img:first-child {
      margin-bottom: 30px;
    }

    img:last-child {
      display: block;
    }
  }
`;

const Img = styled.img`
  max-width: ${({maxWidth}) => maxWidth || 'none'};
  width: 100%;
  height: auto;

  ${({maxWidth}) => maxWidth && css`
    margin-left: auto;
    margin-right: auto;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: #303030;
    padding: 1rem 3rem;
  }

  a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #ff940a;
  }
`;

const IconLabel = styled.span`
  font-weight: 600;
  margin: 1rem 0;
`;

const IconItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    text-align: center;
    color: ${({theme}) => theme.colors.white};
    font-size: 0.875rem;
  }

  ${IconLabel} {
    font-size: 1.125rem;
  }
`;

const IconList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
  padding-top: 3rem;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media(min-width: 1400px) {
    padding-top: 105px;
    /* margin-top: calc(3rem + 52px); */
    /* margin-top: 3rem; */
  }

  @media(min-width: 1530px) {
    padding-top: 150px;
    /* margin-top: calc(3rem + 104px); */
  }
`;

const SectionText = styled.div`
  span {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1.5rem !important;
  }
`;

function RoomManagementPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('seo.room_management.title')} />
      <Content>
        <TopPanel>
          <StaticImage src="../images/img-panel-03.jpg" className="panel-image" placeholder="blurred" alt={t('photo_alt')} />
          <LangSelector />
          <Title as="h1">{t('room_management.title')}</Title>
        </TopPanel>
        <Section>
          <ContentWrapper>
            <Subtitle as="h2">{t('room_management.block_1.title')}</Subtitle>
            <CenteredText>
              <p>{t('room_management.block_1.line_1')}</p>
              <p>{t('room_management.block_1.line_2')}</p>
            </CenteredText>
          </ContentWrapper>
        </Section>
        <DarkSection>
          <ContentWrapper>
            <Grid>
              <div>
                <ImgGrid>
                  <Img maxWidth="600px" src={img01Src} alt={t('photo_alt')} />
                  <Img maxWidth="600px" src={img02Src} alt={t('photo_alt')} />
                </ImgGrid>
               </div>
              <div>
                <LeftSubtitle as="h2">{t('room_management.block_2.title')}</LeftSubtitle>
                <LeftText>
                  <p>{t('room_management.block_2.line_1')}</p>
                  <p>
                    <Trans i18nKey="room_management.block_2.line_2">
                      message <strong>message</strong> message
                    </Trans>
                  </p>

                </LeftText>
              </div>
            </Grid>
            <IconList>
              <IconItem>
                <ProfitIcon />
                <IconLabel>{t('room_management.icon_list.icon_1_label')}</IconLabel>
                <span>{t('room_management.icon_list.icon_1_value')}</span>
              </IconItem>
              <IconItem>
                <IncreaseIcon />
                <IconLabel>{t('room_management.icon_list.icon_2_label')}</IconLabel>
                <span>{t('room_management.icon_list.icon_2_value')}</span>
              </IconItem>
              <IconItem>
                <PriceIcon />
                <IconLabel>{t('room_management.icon_list.icon_3_label')}</IconLabel>
                <span>{t('room_management.icon_list.icon_3_value')}</span>
              </IconItem>
            </IconList>
          </ContentWrapper>
        </DarkSection>
        <SpecialSection>
          <ContentWrapper>
            <Grid>
              <div>
                <LeftSubtitle as="h2">{t('room_management.block_3.title')}</LeftSubtitle>
                <LeftText>
                  <p>{t('room_management.block_3.line_1')}</p>
                  <SectionText>
                    <span>{t('room_management.block_3.section_1_label')}</span>
                    <p>{t('room_management.block_3.section_1_value')}</p>
                  </SectionText>
                  <SectionText>
                    <span>{t('room_management.block_3.section_2_label')}</span>
                    <p>{t('room_management.block_3.section_2_value')}</p>
                  </SectionText>
                  <SectionText>
                    <span>{t('room_management.block_3.section_3_label')}</span>
                    <p>{t('room_management.block_3.section_3_value')}</p>
                  </SectionText>
                </LeftText>
              </div>
              <Img src={img03Src} maxWidth="600px" alt={t('photo_alt')} />
            </Grid>
          </ContentWrapper>
          <Gallery />
          <ButtonWrapper>
            <Link to={APARTMENT_SELECTION}>{t('select_apartment')}</Link>
          </ButtonWrapper>
        </SpecialSection>
        <Map />
      </Content>
    </Layout>
  )
}

export default RoomManagementPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
