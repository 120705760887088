import styled from 'styled-components';

const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  z-index: 2;


  @media(min-width: 768px) {
    padding-left: 30px;
  }
`;

export default ContentWrapper;