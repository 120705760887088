import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const DATA = [1,2,3,4,5];

const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  z-index: 2;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const Wrapper = styled.div`
  padding: 70px 0 50px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;

  @media(min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 768px) {
    column-gap: 1rem;
    row-gap: 1rem;
  }
`;

const SubGal = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;

  @media(min-width: 768px) {
    column-gap: 1rem;
    row-gap: 1rem;
  }
`;

function Gallery() {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [images, setImages] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setImages(
      DATA.map(item => `/gallery/img-0${item}.jpg`)
    )
  },[]);

  return (
    <ContentWrapper>
      { isOpen && (
        <Lightbox
          reactModalProps={{ shouldReturnFocusAfterClose: false }}
          enableZoom={false}
          closeLabel={t('gallery.close')}
          prevLabel={t('gallery.prev')}
          nextLabel={t('gallery.next')}
          mainSrc={images[selected]}
          nextSrc={images[(selected + 1) % DATA.length]}
          prevSrc={images[(selected + DATA.length - 1) % DATA.length]}
          onCloseRequest={e => { setOpen(false);
          }}
          onMovePrevRequest={() =>
            setSelected((selected + DATA.length - 1) % DATA.length)
          }
          onMoveNextRequest={() =>
            setSelected((selected + 1) % DATA.length)
          }
        />
      )}
    <Wrapper>
        <Img src="/gallery/img-01-th.jpg" alt={t('photo_alt')} onClick={() => { setSelected(0); setOpen(true);}} />
        <SubGal>
          <Img src="/gallery/img-02-th.jpg" alt={t('photo_alt')} onClick={() => { setSelected(1); setOpen(true);}} />
          <Img src="/gallery/img-03-th.jpg" alt={t('photo_alt')} onClick={() => { setSelected(2); setOpen(true);}} />
          <Img src="/gallery/img-04-th.jpg" alt={t('photo_alt')} onClick={() => { setSelected(3); setOpen(true);}} />
          <Img src="/gallery/img-05-th.jpg" alt={t('photo_alt')} onClick={() => { setSelected(4); setOpen(true);}} />
        </SubGal>
      </Wrapper>
    </ContentWrapper>
  );
}

export default Gallery;