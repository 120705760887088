import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 350px;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media(min-width: 768px) {
    height: 550px;
  }
`;

function Map() {
  return (
    <Wrapper>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2622.6824804577236!2d16.547818052142304!3d48.90238812574909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x33e357fb78f58591!2zNDjCsDU0JzA5LjAiTiAxNsKwMzInNTguOCJF!5e0!3m2!1scs!2scz!4v1645564223625!5m2!1scs!2scz" title="map" allowFullScreen="" loading="lazy"></iframe>
    </Wrapper>
  );
}

export default Map;